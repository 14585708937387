import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'tracking_number_form',
    };
  },
  async created() {
    await this.getFormRule('tracking_number_form');
  },
  methods: {
    // 设置规则
    setRule(item) {
      // console.log('item', item);
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;
      if (type === 'add') {
        request.get('/cps/v1/fxhTerminalInterestRecord/findMobileById', {
          id,
        }).then((res) => {
          if (res.success) {
            console.log(res, 'res');
            const { result } = res;
            this.setValue({
              ...result,
            });
          }
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        console.log('🚀 ~ submit ~ formData:', formData);
        const params = {
          ...formData,
          id: this.formConfig.id,
        };
        const url = '/cps/v1/fxhTerminalInterestRecord/addLogisticsCode';
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};

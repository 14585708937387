<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import Form from '../form';
import NumberForm from '../form/number';

export default {
  extends: TablePage,
  name: 'equity_redemption_record_list',
  components: {
    Form,
    Modal,
    NumberForm,
  },
  data() {
    return {
      // requestType: 'GET',
    };
  },
  created() {
    this.getConfigList('equity_redemption_record_list');
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      console.log({ val, row });

      if (
        ['view_receiving_details', 'fill_tracking_number'].includes(val.code)
      ) {
        this.formConfig = {};
        this.formName = 'Form';
        this.modalConfig.width = '70%';
        this.modalConfig.height = '600px';
        this.formConfig = {
          type: val.code,
          id: row.id || '',
          code: val.code || '',
        };
        if (val.code === 'view_receiving_details') {
          this.modalConfig.title = '签收记录';
          this.formConfig.type = 'view';
          this.formConfig.code = 'view';
          this.openFull();
        } else if (val.code === 'fill_tracking_number') {
          this.modalConfig.width = '30%';
          this.modalConfig.height = '300px';
          this.formName = 'NumberForm';
          this.modalConfig.title = '物流单号维护';
          this.formConfig.type = 'add';
          this.formConfig.code = 'add';
          this.openModal();
        }
        console.log('this.formConfig', this.formConfig);
      }
    },
    // // 按钮状态显隐
    clickVisible({ val, row }) {
      if (val.code === 'fill_tracking_number') {
        const isShow = row.status === 1;

        return isShow;
      }

      return true;
    },
  },
};
</script>
